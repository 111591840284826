<template>
  <v-layout class="page" fill-height>
    <div class="conBox step3">
      <ul class="sTab">
        <li v-for="(item, i) in tabList" :key="i">
          <a :class="item.class" @click.prevent="changeTab(i)" v-ripple>{{ item.name }}</a>
        </li>
      </ul>
      <ul class="detTab">
        <li class="cobb" :class="{ 'on': curIndex == 0 }" v-show="curIndex == 0" :key="'cobb'">
          <ul class="cobbDiv">
            <li>
              <div class="contTitW">
                <p class="contTit">{{ READING_SPINE_NAME }} 분석 결과</p>
              </div>
              <spine-rslt-compo v-if="xrayMap.spine" :xrayMap="xrayMap.spine" />
            </li>
            <li class="history">
              <div class="contTitW">
                <p class="contTit">히스토리 비교</p>
                <div class="btnW" style="width:auto;">
                  <button class="btn solid small" v-ripple @click="historySpinePop = true;"><img src="../../../assets/images/data_check.svg" alt="">이력 선택</button>
                  <button class="btn default small" @click="graphSpinePop = true; graphSpineKey += 1;" v-ripple><img src="../../../assets/images/query_stats.svg" alt="">그래프 조회</button>
                </div>
              </div>
              <ul class="historyList" v-if="historySpineImageList && historySpineImageList.length > 0">
                <li class="xrayImg" v-for="(item, i) in historySpineImageList" :key="i">
                  <spine-rslt-compo :xrayMap="item" />
                </li>
              </ul>
              <div class="emptyBox" v-else>[이력 선택] 버튼을 클릭하여<br />비교할 이력을 선택하세요.</div>
            </li>
          </ul>
        </li>
        <li class="bone" :class="{ 'on': curIndex == 1 }" v-show="curIndex == 1" :key="'bone'">
          <ul class="boneDiv">
            <li class="topDiv">
              <div class="div">
                <div class="styleTitW">
                  <p class="styleTit">{{ READING_RISSER_NAME }} 분석 결과</p>
                </div>
                <risser-rslt-compo v-if="xrayMap.spine" :title="READING_RISSER_NAME + ' 분석 결과'" :xrayMap="xrayMap.spine" />
              </div>
              <div class="div">
                <div class="styleTitW">
                  <p class="styleTit">환자 정보</p>
                </div>
                <div class="patient-info">
                  <table class="patient-table">
                    <tr>
                      <th>환자번호</th>
                      <td>{{ targetInfo.patientSn }}</td>
                    </tr>
                    <tr>
                      <th>이름</th>
                      <td>{{ targetInfo.name }}</td>
                    </tr>
                    <tr>
                      <th>생년월일</th>
                      <td>{{ targetInfo.birth }}</td>
                    </tr>
                    <tr>
                      <th>생리학적 나이</th>
                      <td>{{ targetInfo.age }}</td>
                    </tr>
                    <tr>
                      <th>성별</th>
                      <td>{{ targetInfo.sex === 0 ? '남' : targetInfo.sex === 1 ? '여' : '' }}</td>
                    </tr>
                    <tr>
                      <th>기타사항</th>
                      <td>{{ targetInfo.otherDetails }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </li>
            <li class="botDiv">
              <div class="titW">
                <div class="styleTitW">
                  <p class="styleTit">히스토리 비교</p>
                  <div class="hisBtnW">
                    <button class="btn solid small" @click="historyRisserPop = true;" v-ripple><img src="../../../assets/images/data_check.svg" alt=""> 이력 선택</button>
                    <button class="btn default small" @click="graphRisserPop = true;  graphRisserKey += 1;" v-ripple><img src="../../../assets/images/query_stats.svg" alt="">그래프 조회</button>
                  </div>
                </div>
              </div>
              <ul class="imgDiv" v-if="historyRisserImageList && historyRisserImageList.length > 0">
                <risser-rslt-compo v-for="(item, i) in historyRisserImageList" :key="i" :xrayMap="item" />
              </ul>
              <ul class="imgDiv" v-else>
                <li class="emptyBox">비교 히스토리가 없습니다. </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="result" :class="{ 'on': curIndex === 2 }" v-show="curIndex === 2" :key="'result'">
          <tot-rslt-compo :xrayMap="xrayMap" :xrayNo="xrayNo"/>
        </li>
      </ul>
    </div>
    <transition name="fade">
      <history-popup v-if="historySpinePop" :xrayType="XRAY_TYPE_SPINE" :historyNoList="historySpineNoList" :xrayNo="xrayMap?.spine?.xrayNo" @selectHistory="selectHistory" @closePopup="historySpinePop = false;" />
      <history-popup v-if="historyRisserPop" :xrayType="XRAY_TYPE_SPINE" :historyNoList="historyRisserNoList" :xrayNo="xrayMap?.spine?.xrayNo" @selectHistory="selectHistory" @closePopup="historyRisserPop=false;" />
      <graph-popup v-if="graphSpinePop" :xrayName="READING_SPINE_NAME" :xrayType="XRAY_TYPE_SPINE" :xrayNo="xrayMap?.spine?.xrayNo" graphType="maxAngle" @closePopup="graphSpinePop=false;" :key="graphSpineKey"/>
      <graph-popup v-if="graphRisserPop" :xrayName="READING_RISSER_NAME" :xrayType="XRAY_TYPE_SPINE" :xrayNo="xrayMap?.spine?.xrayNo" graphType="risser" @closePopup="graphRisserPop=false;" :key="graphRisserKey" />
    </transition>
  </v-layout>
</template>

<script>
import API_RESULT from "../../../API/reading/result";
import API_HISTORY_IMAGE from "../../../API/reading/historyImage";
import historyPopup from "../../../popup/historyPopup";
import graphPopup from "../../../popup/graphPopup";
import EventBus from "../../../plugins/EventBus";
import Constants from '../../../plugins/Const'

export default {
    components: {
        historyPopup,
        graphPopup,
        totRsltCompo: () => import('@/components/totRsltCompo.vue'),
    },
    inject: ["setRightSideHidden"],
    data() {
      return {
        tabHeader: {
          spine: { name: Constants.READING_SPINE_NAME, class: '' },
          risser: { name: Constants.READING_RISSER_NAME, class: '' },
          tot: { name: "통합 분석 결과", class: "" },
        },
        tabList: [],
        curIndex: 0,
        xrayMap: {
          spine: {},
        },
        historySpinePop: false,
        historyRisserPop: false,
        historySpineNoList: [],
        historySpineImageList: [],
        historyRisserNoList: [],
        historyRisserImageList: [],
        graphSpinePop: false,
        graphRisserPop: false,
        atResultKey: 0,
        graphRisserKey: 0,
        graphSpineKey: 0,
        xrayNo: this.$route.params.no,
        type: this.$route.params.type,
        pineDx: process.env.VUE_APP_PINE_DX,
        targetInfo: {},
        XRAY_TYPE_SPINE: Constants.XRAY_TYPE_SPINE,
        XRAY_TYPE_RISSER: Constants.XRAY_TYPE_RISSER,
      };
    },
    created() { 
      this.setTargetInfo();
    },
    mounted() {
      this.setRightSideHidden(true)
      this.getResult(this.$route.params.no);
    },
  destroyed() { 
      this.setRightSideHidden(false);
      this.initLocalStorage();
    },
    methods: {
    async getResult(no) {
      let res = await API_RESULT.request(no);
      if (res.isSuccess) {
        const resMap = {
          spine: res.spine,
        };
        this.xrayMap = resMap;
        if (this.xrayMap?.spine) this.roundAngles(this.xrayMap?.spine);
        EventBus.emit("reading_end", this.xrayMap);
        this.setTab();
        this.atResultKey += 1;
      } else {
        this.showPopup(res.errorMsg, res.status);
      }
    },
      setTab() {
      if (this.$route.params.type) {
        let type = this.$route.params.type;
        switch (type) {
          case 'spine':
            this.curIndex = 0
            break;
          case 'risser':
            this.curIndex = 1
            break;
        }
      }
      this.tabList.push(this.tabHeader.spine);
      this.tabList.push(this.tabHeader.risser);
      this.tabList.push(this.tabHeader.tot);
      if (this.tabList && this.tabList.length > 0) this.tabList[0].class = "on";
    },
      changeTab(index) {
      this.tabList.forEach((item) => {
        item.class = "";
      });
      this.tabList[index].class = "on";
      this.curIndex = index;
    },
    selectHistory(xrayType, event) {
      if (!event || (event && event.length == 0)) {
        if (this.curIndex == 0) {
          this.historySpineNoList = [];
          this.historySpineImageList = [];
        } else if (this.curIndex == 1) {
          this.historyRisserNoList = [];
          this.historyRisserImageList = [];
        }
      } else {
        if (this.curIndex == 0) {
          this.historySpineNoList = event;
          this.getSpineHistoryImage(this.XRAY_TYPE_SPINE, this.historySpineNoList);
        } else if (this.curIndex == 1) {
          this.historyRisserNoList = event;
          this.getSpineHistoryImage(this.XRAY_TYPE_SPINE, this.historyRisserNoList);
        }
    	}
    	this.historySpinePop = false;
      	this.historyRisserPop = false;
    },
    async getSpineHistoryImage(xrayType, historyNoList) {
      let res = await API_HISTORY_IMAGE.request(xrayType, historyNoList);
      if (res.isSuccess) {
        if (this.curIndex == 0 && xrayType == this.XRAY_TYPE_SPINE) {
          this.historySpineImageList = res.list;
          for (let image of this.historySpineImageList) {
            this.roundAngles(image);
          }
        } else if (this.curIndex == 1 && xrayType == this.XRAY_TYPE_SPINE) {
          this.historyRisserImageList = res.list;
        }
      } else {
        this.showPopup(res.errorMsg, res.status);
      }
    },
    setTargetInfo() {
      const targetInfo = JSON.parse(localStorage.getItem('targetInfo'));
      if (targetInfo) {
        this.targetInfo = targetInfo;
      } else {
        this.targetInfo = {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/app.scss";
.v-application {
  .blue, .red {background-color: transparent !important; border-color: transparent !important;}
  .totalReportView {position: absolute; right: 40px; top: 40px;}
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

button{
  transition: box-shadow 0.5s, opacity 0.5s;
}
button:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.7;
}

.cobbDiv .contTitW{
  position:relative;
  height: auto;
  min-height: 32px;
  .contTit{
    position: relative;
    padding-left: 16px;
    color: var(--color-s80);
    font-size: 20px;
    font-weight: 600;
  }
}

.bone .boneDiv .botDiv .titW{margin-bottom: 0;}
.bone .boneDiv .styleTitW{width: 100%; position: relative; height: auto; min-height: 32px; margin-bottom: 16px; display: flex; align-items: center; justify-content: space-between;}
.bone .boneDiv  .styleTit{margin-bottom: 0;}


</style>
